import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import Card from '@material-ui/core/Card'
import SortIcon from '@material-ui/icons/ArrowDownward'
import PulseLoader from 'react-spinners/PulseLoader'
import Swal from 'sweetalert2'
import moment from 'moment'
import DataTableExtensions from 'react-data-table-component-extensions'
import 'react-data-table-component-extensions/dist/index.css'
import '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import {
  ControlPointDuplicateOutlined,
  FormatAlignJustifyTwoTone,
} from '@material-ui/icons'
import Icon, { FontAwesome, Feather, AntDesign } from 'react-web-vector-icons'
import pedente from '../../assets/images/warn.png'

const api = process.env.REACT_APP_HOST
const tbc = process.env.REACT_APP_HOST_2

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

export default function TabelaMovimento(props) {
  const classes = useStyles()
  const [isload, setIsload] = useState(false)
  const [dadosPrefeitura, setDadosPrefeitura] = useState(null)
  const [selectedData, setSelectedData] = useState()

  useEffect(() => {
    // function handleValidacaoClicked(id){
    //   alert(' was clicked!');
    // }
    console.log(props)
  }, [])

  function handleEnviaTodasNotas() {
    console.log('Deu bom!!!!!')
  }

  function handleEnviaNota(id) {
    setDadosPrefeitura(JSON.parse(props.dadosPref))
    console.log(JSON.parse(props.dadosPref))
    var dsPrefeitura = JSON.parse(props.dadosPref)

    var movHeaders = new Headers()
    movHeaders.append('Content-Type', 'application/json')
    movHeaders.append('Cookie', 'ci_session=n2unia7lcanl13qq7tthast0d821n7ed')

    var rawmov = JSON.stringify({
      codcoligada: localStorage.getItem('@integrador-app/codcoligada'),
      codfilial: localStorage.getItem('@integrador-app/codfilial'),
      idmov: id,
      codtitmov: localStorage.getItem('@integrador-app/codtpmov'),
    })

    var requestOptions = {
      method: 'POST',
      headers: movHeaders,
      body: rawmov,
      redirect: 'follow',
    }

    fetch(`${api}/idmovimento`, requestOptions)
      .then((response) => {
        setIsload(false)
        return response.json()
      })
      .then((movimento) => {
        //console.log(movimento.dados);
        // Envio a nota
        var notaHeaders = new Headers()
        notaHeaders.append('Accept', 'application/json')
        notaHeaders.append('Content-Type', 'application/json')
        notaHeaders.append(
          'Authorization',
          'Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw'
        )

        async function alunoDadosNota(id, coligada, filial) {
          var myHeaders = new Headers()
          myHeaders.append(
            'Cookie',
            'ci_session=or8htv6bqvpffq0942kg5akdntasigap'
          )

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
          }

          var dados = await fetch(
            `${api}/getObsNota?codcoligada=${coligada}&codfilial=${filial}&idmov=${id}`,
            requestOptions
          )
            .then((response) => response.json())
            .then((result) => {
              return result.dados[0].dados
            })
            .catch((error) => console.log('error', error))

          if (dados) {
            return dados
          } else {
            return null
          }
        }

        //console.log('Retorno da promisse', alunoDadosNota(id, localStorage.getItem("@integrador-app/codcoligada"), localStorage.getItem("@integrador-app/codfilial")));

        alunoDadosNota(
          id,
          localStorage.getItem('@integrador-app/codcoligada'),
          localStorage.getItem('@integrador-app/codfilial')
        )
          .then((retorno) => {
            const codmunicip = localStorage.getItem(
              '@integrador-app/codmunicip'
            )
            const dadosNota = {
              //ambienteEmissao: "homologacao",
              ambienteEmissao: 'producao',
              tipo: 'NFS-e',
              idExterno: id.toString(),
              cliente: {
                tipoPessoa: movimento.dados[0].PESSOAFISOUJUR,
                nome: movimento.dados[0].TomadorRazaoSocial,
                email: movimento.dados[0].TomadorContatoEmail,
                cpfCnpj:
                  movimento.dados[0].TomadorIdentificacaoTomadorCpfCnpjCnpj,
                inscricaoMunicipal:
                  movimento.dados[0]
                    .TomadorIdentificacaoTomadorInscricaoMunicipal,
                inscricaoEstadual:
                  movimento.dados[0]
                    .TomadorIdentificacaoTomadorInscricaoEstadual,
                telefone: movimento.dados[0].TomadorContatoTelefone,
                endereco: {
                  uf: movimento.dados[0].TomadorEnderecoUf,
                  cidade: movimento.dados[0].TomadorEnderecoCidade,
                  logradouro: movimento.dados[0].TomadorEnderecoEndereco,
                  numero: movimento.dados[0].TomadorEnderecoNumero,
                  complemento: movimento.dados[0].TomadorEnderecoComplemento,
                  bairro: movimento.dados[0].TomadorEnderecoBairro,
                  cep: movimento.dados[0].TomadorEnderecoCEP,
                },
              },
              enviarPorEmail: false,
              dataCompetencia: movimento.dados[0].DATA_COMPETENCIA,
              servico: {
                descricao: retorno
                  ? retorno.replace(':', ' ') +
                    ' - ' +
                    movimento.dados[0].ServicoDiscriminacao
                  : movimento.dados[0].ServicoDiscriminacao,
                aliquotaIss: parseFloat(
                  movimento.dados[0].ServicoValoresAliquota
                ),
                issRetidoFonte: false,
                codigoServicoMunicipio: dsPrefeitura.usaRegimeEspecialTributacao
                  ? movimento.dados[0].ServicoCodigoTributacaoMunicipio
                  : null,
                descricaoServicoMunicipio:
                  movimento.dados[0].ServicoDiscriminacao,
                itemListaServicoLC116:
                  dsPrefeitura.usaItemListaServico || codmunicip === '4104204'
                    ? movimento.dados[0].ServicoItemListaServico
                    : null,
                cnae: dsPrefeitura.usaCNAE
                  ? movimento.dados[0].ServicoCodigoCnae
                  : null,
                valorCofins: 0,
                valorCsll: 0,
                valorInss: 0,
                valorIr: 0,
                valorPis: 0,
              },
              valorTotal: parseFloat(
                movimento.dados[0].ServicoValoresValorLiquidoNfse
              ),
              observacoes: '',
              metadados: {},
            }

            var raw = JSON.stringify(dadosNota)
            console.log(dadosNota)

            var requestOptions = {
              method: 'POST',
              headers: notaHeaders,
              body: raw,
              redirect: 'follow',
            }
            setIsload(true)
            fetch(
              `https://api.enotasgw.com.br/v1/empresas/${localStorage.getItem(
                '@integrador-app/idempresa'
              )}/nfes/`,
              requestOptions
            )
              .then((response) => {
                setIsload(false)
                return response.json()
              })
              .then((json) => {
                console.log(json)
                if (json.nfeId !== undefined) {
                  Swal.fire({
                    title:
                      '<span style="font-size: 22px;">Envio de nota fiscal</span>',
                    html: '<p style="font-size: 16px;">Nota Fiscal enviada para a pefeitura, consulta a mesma para a verificação do Status na prefeitura.</p>',
                    icon: 'success',
                    confirmButtonText: 'Sair',
                  })
                } else {
                  Swal.fire({
                    title:
                      '<span style="font-size: 22px;">Envio de nota fiscal</span>',
                    html:
                      '<p style="font-size: 16px;">' +
                      json[0].mensagem +
                      '</p>',
                    icon: 'error',
                    confirmButtonText: 'Sair',
                  })
                }
              })
          })
          .catch((error) => console.log(error))
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  function handleConsultaNota(id) {
    setIsload(true)

    var myHeaders = new Headers()
    myHeaders.append('Accept', 'application/json')
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append(
      'Authorization',
      'Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw'
    )

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    fetch(
      `https://api.enotasgw.com.br/v1/empresas/${localStorage.getItem(
        '@integrador-app/idempresa'
      )}/nfes/porIdExterno/${id}`,
      requestOptions
    )
      .then((response) => {
        setIsload(false)
        if (!response.ok) throw new Error(response.status)
        else return response.json()
      })
      .then((json) => {
        console.log(json)
        var MsgText = ''
        MsgText =
          MsgText +
          `<p align="left" style="font-size: 16px;">Id. Externo: <strong>${json.idExterno}</strong><br>`
        MsgText =
          MsgText +
          `<p align="left" style="font-size: 16px;">Status: <strong>${json.status}</strong><br>`
        if (json.status !== 'Autorizada') {
          MsgText =
            MsgText +
            `<p align="left" style="font-size: 16px;">Obs Status: <strong>${json.motivoStatus}</strong><br>`
        }
        if (json.status === 'Autorizada') {
          MsgText =
            MsgText +
            `<p align="left" style="font-size: 16px;">Número da nota: <strong>${json.numero}</strong><br>`
          MsgText =
            MsgText +
            `<p align="left" style="font-size: 16px;">Protocolo: <strong>${json.codigoVerificacao}</strong><br>`
          MsgText =
            MsgText +
            `<p align="left" style="font-size: 16px;">Data da autorização: <strong>${moment(
              json.dataAutorizacao
            ).format('DD/MM/YYYY')}</strong><br>`
        }
        MsgText =
          MsgText +
          `<p align="left" style="font-size: 16px;">Competência: <strong>${moment(
            json.dataCompetenciaRps
          ).format('MM/YYYY')}</strong><br>`

        const icone =
          json.status === 'Negada'
            ? 'error'
            : json.status === 'Autorizada'
            ? 'success'
            : 'warning'

        if (json.status === 'Autorizada') {
          var authHeaders = new Headers()
          authHeaders.append('Content-Type', 'application/json')
          authHeaders.append(
            'Cookie',
            'ci_session=s1pgeqs2nifofho03ha1grpadv0ei5cm'
          )

          var corpo = JSON.stringify({
            codcoligada: localStorage.getItem('@integrador-app/codcoligada'),
            idmov: id,
            status: 'A',
            numnfse: json.numero,
            xmlnfse: json.linkDownloadXML,
            obs: null,
            numprotocolo: json.codigoVerificacao,
            dtautoriza: moment(new Date()).format('YYYY-MM-DD'),
          })

          //console.log('Link do XML: '+json.linkDownloadXML);

          var requestParams = {
            method: 'POST',
            headers: authHeaders,
            body: corpo,
            redirect: 'follow',
          }

          fetch(`${api}/atualizastatusnfse`, requestParams)
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.log('error', error))
        }

        Swal.fire({
          title:
            '<span style="font-size: 22px;">Consulta de nota fiscal</span>',
          html: MsgText,
          icon: icone,
          confirmButtonText: 'Sair',
        })
        // console.log(result);
      })
      .catch((error) => {
        Swal.fire({
          title: '<span style="font-size: 22px;">Consulta de Nota</span>',
          html: `<p align="center" style="font-size: 16px;">A Nota fiscal não foi encontrada no eNotas. Por favor, verifique se o id ${id} foi enviado pelo TOTVS</p>`,
          icon: 'error',
          confirmButtonText: 'Sair',
        })
        // console.log('error', error)
      })
  }

  const Vlprev = (props) => {
    const [valprev, setValprev] = useState('...')
    const [iconprev, setIconprev] = useState(0)
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Cookie', 'ci_session=t9i5llscl2fmobg3o6mt79lptssij3cp')

    var raw = JSON.stringify({
      codcoligada: localStorage.getItem('@integrador-app/codcoligada'),
      codfilial: localStorage.getItem('@integrador-app/codfilial'),
      idmov: props.idmov,
    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }

    fetch(`${api}/valorprevisto`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setValprev(result.dados[0].VALORPREVISTO)

        if (
          parseFloat(result.dados[0].VALORPREVISTO) !==
          parseFloat(result.dados[0].VALORMOVIMENTO)
        ) {
          setIconprev(1)
        }
      })
      .catch((error) => console.log('error', error))
    return (
      <div>
        {iconprev === 1 ? <img src={pedente} /> : ''}
        {' ' + valprev}
      </div>
    )
  }

  const LoaderComponent = () => (
    <div
      style={{
        height: '150px',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
      }}
    >
      <PulseLoader color={'#1565C0'} loading={true} size={7} />
    </div>
  )

  const data = props.dados

  const columns = [
    {
      name: 'Ref',
      selector: 'IdMovimento',
      maxWidth: '50px',
      sortable: true,
    },
    /*{
      name: "Status",
      selector: "nmstatusmov",
      sortable: true,
      maxWidth: "100px",
    },
    {
      name: "Tipo",
      selector: "tipomovimento",
      maxWidth: "70px",
      sortable: true,
    },*/
    {
      name: 'Dt. Emissão',
      selector: 'DataEmissao',
      sortable: true,
      maxWidth: '150px',
    },
    {
      name: 'Valor Nota',
      selector: 'ServicoValoresValorServicos',
      sortable: true,
      maxWidth: '120px',
      right: true,
    },
    {
      name: 'Tomador',
      selector: 'TomadorRazaoSocial',
      sortable: true,
    },
    {
      name: 'Serviço',
      selector: 'ServicoDiscriminacao',
      sortable: true,
    },
    {
      name: 'Ações',
      cell: (row) => (
        <>
          <div
            onClick={() => handleEnviaNota(row.IdMovimento)}
            style={{
              backgroundColor: '#088555',
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 8,
              paddingBottom: 8,
              textAlign: 'center',
              borderRadius: 5,
              cursor: 'pointer',
              marginRight: '5px',
              color: '#fff',
              fontSize: 14,
            }}
          >
            Enviar{' '}
            <FontAwesome
              // onClick={() => handleEnviaNota(row.IdMovimento)}
              name="cloud-upload"
              color="#fff"
              size={20}
            />
          </div>
          <div
            onClick={() => handleConsultaNota(row.IdMovimento)}
            style={{
              backgroundColor: '#bd9a1e',
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 8,
              paddingBottom: 8,
              textAlign: 'center',
              borderRadius: 5,
              cursor: 'pointer',
              marginRight: '5px',
            }}
          >
            <FontAwesome
              onClick={() => handleConsultaNota(row.IdMovimento)}
              name="refresh"
              color="#fff"
              size={20}
            />
          </div>
        </>
      ),
      allowOverflow: true,
      button: true,
      width: '260px',
    },
  ]

  const NoData = () => {
    return (
      <p
        style={{
          color: '#000',
          fontSize: 16,
          marginTop: 20,
          marginBottom: 70,
        }}
      >
        Não foram encotrados movimento pedentes nesse período.
      </p>
    )
  }

  const tableData = {
    columns,
    data,
  }

  const handleChange = (state) => {
    console.log(state.selectedRows[0].IdMovimento)
  }

  return (
    <>
      {isload ? (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ''
      )}
      <Card>
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            data={props.dados}
            noDataComponent={<NoData />}
            noHeader
            defaultSortFieldId={1}
            paginationPerPage={15}
            sortIcon={<SortIcon />}
            defaultSortAsc={false}
            progressPending={props.load}
            progressComponent={<LoaderComponent />}
            pagination
            highlightOnHover
            selectableRows
            onSelectedRowsChange={handleChange}
          />
        </DataTableExtensions>
      </Card>
    </>
  )
}
